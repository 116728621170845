import React, { Component } from 'react'
import axios from 'axios'
class ItemsContainer extends Component {
  constructor(props) { super(props)
    this.state = {
      item: {name: "Whisky", ingredient: "corn", brand: "Heaven Hill", link: "http://google.com"}
    }
  }
    componentDidMount() {
    axios.get('https://mashbills.com:7003/api/v1/mashbills.json')
    .then(response => {
      console.log(response)
      this.setState({item: response.data[1] })
    })
    .catch(error => console.log(error))
  }

    render() {
    return (<div>
Name: {this.state.item.name}<br />
Brand: {this.state.item.brand}<br />
Ingredient: {this.state.item.ingredient}<br />
Bourbon: {this.state.item.bourbon}<br />





    	</div>)
}
}
export default ItemsContainer
