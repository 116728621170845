import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ItemsContainer from './components/ItemsContainer'

function App() {
  return (
    <div className="App">
      <header className="App-header">
      
       <ItemsContainer />
      </header>
    </div>
  );
}

export default App;
